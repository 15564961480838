import Request from '@/utils/request'

export function getOutboundApi (data) {
  return Request({
    url: '/outbound/list',
    params: data
  })
}

export function saveOutboundApi (data) {
  return Request({
    url: '/outbound/add',
    method: 'post',
    data
  })
}

export function getAllTypeApi () {
  return Request({
    url: '/type/all'
  })
}

export function getSpecByTypeApi (data) {
  return Request({
    url: '/specsbytype',
    params: data
  })
}

export function getStockCountApi (data) {
  return Request({
    url: '/stock/count',
    params: data
  })
}

export function getOutboundDetailApi (data) {
  return Request({
    url: '/outbound/detail',
    params: data
  })
}

export function getTypesApi () {
  return Request({
    url: '/type'
  })
}

export function delOutboundApi (data) {
  return Request({
    url: '/outbound/delete',
    params: data
  })
}
