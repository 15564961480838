<template>
  <el-dialog
    v-model="visible"
    :title="title"
    width="640px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    class="add"
  >
    <el-form ref="form" v-loading="loading" :model="ruleForm" :rules="rules" label-width="80px">
      <el-form-item label="类别" prop="type">
        <el-select
          v-model="ruleForm.type"
          filterable
          placeholder="请选择"
          @change="onChange"
        >
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :label="item.type"
            :value="item._id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="规格" prop="type">
        <el-select
          :disabled="!ruleForm.type"
          filterable
          v-model="ruleForm.specs"
          @change="handleSpecChange"
          placeholder="请选择"
        >
          <el-option
            v-for="(item, index) in specs"
            :key="index"
            :label="item.specs"
            :value="item._id"
          ></el-option>
        </el-select>
      </el-form-item>
      <div v-if="currentStock && currentStock.current" class="currentStock">
        <div class="current">
          <div class="currentDetail_item">
            <span class="label">入库批次：</span>
            <span class="value">{{ currentStock.current.stockId }}</span>
          </div>
          <div class="currentDetail_item">
            <span class="label">该批次入库件数：</span>
            <span class="value">{{ currentStock.current.number }}</span>
          </div>
          <div class="currentDetail_item"></div>
          <div class="currentDetail_item">
            <span class="label">该批次入库单价：</span>
            <span class="value">{{ currentStock.current.price }}</span>
          </div>
          <div class="currentDetail_item">
            <span class="label">该批次入库重量：</span>
            <span class="value">{{ currentStock.current.weight }}</span>
          </div>
          <div class="currentDetail_item">
            <span class="label">该批次总金额：</span>
            <span class="value">{{ currentStock.current.money }}</span>
          </div>
        </div>
        <div class="diff">
          <div class="currentDetail_item">
            <span class="label">该批次剩余重量：</span>
            <span class="value">{{ currentStock.diff }}</span>
          </div>
          <div class="currentDetail_item">
            <span class="label">该批次剩余件数：</span>
            <span class="value">{{ currentStock.numDiff }}</span>
          </div>
        </div>
      </div>
      <el-form-item label="出库类型" prop="outboundType">
        <el-radio-group v-model="ruleForm.outboundType" @change="handleSpecChange">
          <el-radio :label="1">出库</el-radio>
          <el-radio :label="2">退仓</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="重量" prop="weight">
        <el-input v-model="ruleForm.weight" type="number"></el-input>
      </el-form-item>
      <el-form-item label="件数" prop="number">
        <el-input v-model="ruleForm.number" type="number"></el-input>
      </el-form-item>
      <el-form-item label="出库时间" prop="outboundTime">
        <el-date-picker
          v-model="ruleForm.outboundTime"
          type="date"
          value-format="YYYY-MM-DD"
          placeholder="请选择"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="ruleForm.remark"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="onSubmit">保存</el-button>
    </template>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import { ref, computed, reactive, toRefs, onMounted } from 'vue'
import Big from 'big.js'

// import * as math from 'mathjs'
import {
  getAllTypeApi,
  getSpecByTypeApi,
  saveOutboundApi,
  getStockCountApi,
  getOutboundDetailApi
} from './fetch'

export default {
  props: {
    refreshList: Function,
    id: String
  },
  setup (props) {
    const form = ref(null)
    const state = reactive({
      visible: true,
      loading: false,
      ruleForm: {
        type: '',
        specs: '',
        weight: undefined,
        number: undefined,
        outboundType: 1,
        outboundTime: '',
        remark: ''
      },
      types: [],
      specs: [],
      companyList: [],
      currentStock: null,
      rules: {
        type: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        specs: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        weight: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        number: [
          { required: true, message: '请输入', trigger: ['blur', 'change'] }
        ],
        outboundTime: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ],
        outboundType: [
          { required: true, message: '请选择', trigger: ['blur', 'change'] }
        ]
      }
    })

    onMounted(async () => {
      state.loading = true
      state.types = await getAllTypeApi()
      if (props.id) {
        state.ruleForm = await getOutboundDetailApi({
          id: props.id
        })
        state.specs = await getSpecByTypeApi({ typeId: state.ruleForm.type })

        handleSpecChange(state.ruleForm.specs)
      }
      state.loading = false
    })

    const title = computed(() => {
      return props.id ? '编辑出库' : '新增出库'
    })

    const onSubmit = async () => {
      try {
        await form.value.validate()
        if (!props.id) {
          if (!state.currentStock?.current) {
            throw new Error('该规格没有出库记录，无法退仓！')
          }
          if (+state.ruleForm.outboundType === 1) {
            if (+state.ruleForm.weight > +state.currentStock.diff) {
              throw new Error('重量不得超过该批次重量')
            }
            if (+state.ruleForm.number > +state.currentStock.diff) {
              throw new Error('重量不得超过该批次重量')
            }
          }
        }
        const data = _.cloneDeep(state.ruleForm)
        data.weight = +data.weight
        data.number = +data.number
        // if (!props.id) {
        data.price = state.currentStock.current.price
        data.money = new Big(data.weight).times(
          state.currentStock.current.price
        )
        // }
        await saveOutboundApi({
          ...data
        })
        state.visible = false
        props.refreshList()
        ElMessage({
          message: '保存成功！',
          type: 'success'
        })
      } catch (e) {
        ElMessage({
          message: e,
          type: 'error'
        })
      }
    }

    const onChange = async (id) => {
      state.ruleForm.specs = undefined
      state.currentStock = null
      state.specs = await getSpecByTypeApi({ typeId: id })
    }

    const handleSpecChange = async (val) => {
      if (!val) return
      state.currentStock = await getStockCountApi({
        type: state.ruleForm.type,
        outboundType: state.ruleForm.outboundType,
        specs: state.ruleForm.specs
      })
    }

    return {
      ...toRefs(state),
      form,
      title,
      onSubmit,
      onChange,
      handleSpecChange
    }
  }
}
</script>

<style lang="stylus" scoped>
.currentStock {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;

  .current, .diff {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #ccc;
    margin-bottom: 5px;
    padding-bottom: 5px;

    .currentDetail_item {
      width: 190px;
      white-space: nowrap;

      .label {
        display: inline-block;
        width: 112px;
        text-align: right;
      }
    }
  }
}
</style>
